import React from 'react';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports.js';

Amplify.configure(awsExports);

function Signup() {
  const handleAccess = () => {
    const oauthDomain = "tickrtrends.auth.us-east-2.amazoncognito.com"; // Cognito domain
    const clientId = "6vk20av8lf26ompgr4r982t0n"; // Cognito App Client ID
    const redirectUri = "https://tickrtrends.com/members"; // Redirect after login/signup
    const responseType = "code"; // Authorization Code Flow
    const scope = "openid email profile"; // OIDC Scopes

    // Build the Hosted UI URL
    const authUrl = `https://${oauthDomain}/oauth2/authorize?client_id=${clientId}&response_type=${responseType}&scope=${encodeURIComponent(
      scope
    )}&redirect_uri=${encodeURIComponent(redirectUri)}`;

    // Redirect user to the Hosted UI
    window.location.href = authUrl;
  };

  return (
    <div style={{ maxWidth: '400px', margin: '0 auto', padding: '20px' }}>
      <h2>Access Tickrtrends</h2>
      <p>
        Sign up or log in using our secure OAuth-based system to access a curated,
        data-driven list of S&P 500 stock valuations.
      </p>
      <button
        onClick={handleAccess}
        style={{
          width: '100%',
          padding: '10px',
          backgroundColor: '#007bff',
          color: '#fff',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        Sign in | Sign up
      </button>
    </div>
  );
}

export default Signup;
