import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import AWS from "aws-sdk";
import Papa from "papaparse";

const MembersPage = () => {
  const auth = useAuth();
  const [data, setData] = useState([]);
  const [downloadUrl, setDownloadUrl] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Redirect to sign-in if not authenticated
    if (!auth.isAuthenticated && !auth.isLoading) {
      auth.signinRedirect();
    }

    // Fetch S3 data only if authenticated
    if (auth.isAuthenticated) {
      AWS.config.update({
        region: "us-east-2", // Your AWS region
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: "us-east-2:76b04baa-5f7c-4428-b1f0-4292735afa79", // Your Identity Pool ID
        }),
      });

      const s3 = new AWS.S3();
      const bucketName = "stockappstorage";

      s3.listObjectsV2({ Bucket: bucketName }, (err, data) => {
        if (err) {
          console.error("Error listing objects:", err);
          setError("Unable to retrieve data from the server.");
          setLoading(false);
          return;
        }

        const latestFile = data.Contents
          .filter((obj) => obj.Key.endsWith(".csv"))
          .sort((a, b) => new Date(b.LastModified) - new Date(a.LastModified))[0];

        if (!latestFile) {
          setError("No CSV file found.");
          setLoading(false);
          return;
        }

        s3.getSignedUrl(
          "getObject",
          { Bucket: bucketName, Key: latestFile.Key, Expires: 60 },
          (err, url) => {
            if (err) {
              console.error("Error generating signed URL:", err);
              setError("Unable to generate download link.");
              setLoading(false);
              return;
            }

            setDownloadUrl(url);

            fetch(url)
              .then((response) => response.text())
              .then((csvText) => {
                Papa.parse(csvText, {
                  header: true,
                  complete: (result) => {
                    setData(result.data.slice(0, 50)); // Display top 50 rows
                    setLoading(false);
                  },
                });
              })
              .catch((fetchError) => {
                console.error("Error fetching CSV file:", fetchError);
                setError("Unable to fetch the CSV file.");
                setLoading(false);
              });
          }
        );
      });
    }
  }, [auth]);

  const handleSignOut = () => {
    const clientId = "6vk20av8lf26ompgr4r982t0n";
    const logoutUri = "https://tickrtrends.com/";
    const cognitoDomain = "https://tickrtrends.auth.us-east-2.amazoncognito.com";

    const signOutUrl = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(
      logoutUri
    )}`;
    window.location.href = signOutUrl;
  };

  if (auth.isLoading || loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      {/* Meta tag for responsive scaling */}
      <style>
        {`
          @media (max-width: 768px) {
            table {
              font-size: 12px;
              width: 100%;
            }
            th, td {
              padding: 5px;
            }
            h1, h2 {
              font-size: 18px;
            }
          }

          @media (max-width: 480px) {
            table {
              font-size: 10px;
            }
            th, td {
              padding: 4px;
            }
            h1 {
              font-size: 16px;
            }
            h2 {
              font-size: 14px;
            }
          }

          body {
            zoom: 1;
          }

          @media (max-width: 768px) {
            body {
              zoom: 0.9; /* Adjust zoom for smaller devices */
            }
          }
        `}
      </style>
      <h1>Welcome, {auth.user?.profile.email}</h1>
      <h2>Latest Stock FFV Analysis</h2>

      <div style={{ overflowX: "auto", marginTop: "20px" }}>
        <table
          border="1"
          cellPadding="10"
          style={{
            borderCollapse: "collapse",
            width: "80%",
            textAlign: "center",
            margin: "0 auto", // Center the table
          }}
        >
          <thead>
            <tr>
              {data.length > 0 &&
                Object.keys(data[0]).map((key) => <th key={key}>{key}</th>)}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                {Object.values(row).map((value, i) => (
                  <td key={i}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div style={{ marginTop: "20px" }}>
        <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
          Download Full CSV
        </a>
      </div>

      <button onClick={handleSignOut} style={{ marginTop: "20px" }}>
        Sign Out
      </button>
    </div>
  );
};

export default MembersPage;
