const awsconfig = {
  aws_project_region: "us-east-2",
  aws_cognito_identity_pool_id: "us-east-2:76b04baa-5f7c-4428-b1f0-4292735afa79",
  aws_cognito_region: "us-east-2",
  aws_user_pools_id: "us-east-2_0P2WZow21",
  aws_user_pools_web_client_id: "6vk20av8lf26ompgr4r982t0n",
  oauth: {
    domain: "tickrtrends.auth.us-east-2.amazoncognito.com",
    scope: ["openid", "profile", "email"],
    redirectSignIn: "https://tickrtrends.com/members",
    redirectSignOut: "https://tickrtrends.com/",
    responseType: "code",
  },
};

export default awsconfig;
