import React from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import Signup from "./Signup.js";
import PaymentPage from "./PaymentPage.js";
import TermsOfService from "./TermsOfService.js";
import MembersLogin from "./MemberLogin.js";
import ConfirmEmail from "./ConfirmEmail.js";

function HomePage() {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <header style={{ maxWidth: "600px", margin: "0 auto" }}>
        <h1>Welcome to Tickrtrends</h1>
        <p>
          Tickrtrends provides a curated, data-driven list of **S&P 500 stock valuations** updated weekly. For just **$4.99 a month**, you'll gain access to a downloadable CSV file, offering detailed calculations of stocks' **forward fair value**.
        </p>
        <ul style={{ textAlign: "left", maxWidth: "500px", margin: "0 auto" }}>
          <li><strong>Discover insights:</strong> Analyze stocks with data-backed forward fair value calculations.</li>
          <li><strong>Stay informed:</strong> Updated weekly with the latest valuation metrics.</li>
        </ul>
        <p>
          <strong>Disclaimer:</strong> Tickrtrends does not provide financial, investment, or legal advice. The data and tools offered are for informational purposes only. Users should consult with a licensed professional for any financial decisions. Use of this platform does not guarantee any financial gains or outcomes.
        </p>
        <button 
          style={{ 
            backgroundColor: "#007bff", 
            color: "white", 
            padding: "10px 20px", 
            border: "none", 
            borderRadius: "5px", 
            cursor: "pointer" 
          }} 
          onClick={() => navigate("/signup")}
        >
          Access Tickrtrends
        </button>
        <p>
          By using our platform, you agree to our <a href="/terms-of-service" style={{ color: "#007bff" }}>Terms of Service</a>. 
          For support, contact us via email at: 
          <a href="mailto:ryan@tickrtrends.com" style={{ color: "#007bff" }}> ryan@tickrtrends.com</a>.
        </p>
      </header>
    </div>
  );
}


function App() {
  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/members" element={<MembersLogin />} />
        <Route path="/confirm-email" element={<ConfirmEmail />} />
        <Route path="*" element={<div>404 - Page Not Found</div>} />
      </Routes>
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
