import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { confirmSignUp } from '@aws-amplify/auth';

function ConfirmEmail() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [username, setUsername] = useState(state?.username || ''); // Email as username
  const [code, setCode] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  // Debugging: Log initial username
  useEffect(() => {
    console.log('Initial username:', username);
    if (!username) {
      setError('Email is required to confirm your account. Please re-enter.');
      navigate('/signup');
    }
  }, [username, navigate]);

  const handleConfirm = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess(false);
  
    if (!username.trim()) {
      setError('Email (username) is required.');
      return;
    }
  
    console.log('Username (email) being sent to confirmSignUp:', username);
    console.log('Confirmation Code:', code);
  
    try {
      await confirmSignUp(username.trim(), code.trim());
      setSuccess(true);
      alert('Email confirmed successfully! Redirecting to login...');
      navigate('/login');
    } catch (err) {
      console.error('Error confirming email:', err);
      setError(err.message || 'Failed to confirm email. Please try again.');
    }
  };
  
  return (
    <div style={{ maxWidth: '400px', margin: '0 auto', padding: '20px' }}>
      <h2>Confirm Your Email</h2>
      {success && <p style={{ color: 'green' }}>Email confirmed successfully!</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleConfirm}>
        <label>Email (used as Username)</label>
        <input
          type="text"
          placeholder="Enter your email"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <label>Verification Code</label>
        <input
          type="text"
          placeholder="Enter the verification code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          required
        />
        <button type="submit">Confirm Email</button>
      </form>
    </div>
  );
}

export default ConfirmEmail;
