import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppWrapper from './App.js'; // Explicitly add `.js` extension
import reportWebVitals from './reportWebVitals.js'; // Explicitly add `.js` extension
import { AuthProvider } from 'react-oidc-context';

// OIDC Configuration for AWS Cognito
const oidcConfig = {
  authority: "https://cognito-idp.us-east-2.amazonaws.com/us-east-2_0P2WZow21",
  client_id: "6vk20av8lf26ompgr4r982t0n",
  redirect_uri: "https://tickrtrends.com/members",
  response_type: "code",
  scope: "openid email profile",
};


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <AppWrapper />
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals(console.log);
